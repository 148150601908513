.contact {
  min-height: 100vh;
  padding: 15vh 0;
  background-color: whitesmoke;

  .contact-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    font-weight: bold;
  }

  .contact-container {
    .contact-display {
      padding: 20px 10px;
      height: 85vh;
      overflow-y: scroll;
      background: white;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
        0 5px 15px 0 rgba(0, 0, 0, 0.08);

      .comment {
        display: flex;
        flex-direction: row;
        margin: 10px 0;

        .comment-info {
          width: 15%;

          img {
            width: 60%;
            border-radius: 50%;
            border: 1px solid gray;
          }
        }

        .comment-content {
          width: 100%;
          position: relative;

          .triangle-left {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-right: 15px solid rgba(216, 112, 147, 0.6);
            border-bottom: 10px solid transparent;
            position: absolute;
            top: 22px;
            left: 0;
            transform: translate(-15px, 0);
          }

          .triangle-left-blue {
            @extend .triangle-left;
            border-right: 15px solid paleturquoise;
          }

          .comment-dateTime {
            font-family: "Dancing Script", cursive;
            font-size: 0.9rem;
            display: block;
            transform: translate(0, -10px);
          }

          .comment-text {
            background-color: rgba(216, 112, 147, 0.6);
            min-height: 50px;
            width: 60%;
            transform: translate(0, -8px);
            padding: 10px 15px;
          }

          .comment-text-blue {
            @extend .comment-text;
            background-color: paleturquoise;
          }
        }
      }
    }

    .contact-form {
      img {
        width: 10%;
        border-radius: 50%;
        border: 1px solid gray;
        z-index: 2;
      }

      .form-username {
        line-height: 30px;
        width: 200px;
        padding: 5px 10px;
        border: none;
        border-bottom: 1px solid black;
        background: none;
        transform: translate(0, -10px);

        &:focus {
          outline: none;
        }
      }

      .form-button {
        background: palevioletred;
        &:hover {
          background: $cardinal;
        }
      }
    }
  }
}

@keyframes image-board-grow {
  0% {
    height: 0;
    padding: 0 0;
    overflow: hidden;
  }
  95% {
    height: 330px;
    padding: 40px 0;
    overflow: hidden;
  }
  100% {
    height: 330px;
    padding: 40px 0;
    overflow: visible;
  }
}

.profile-image-outercontainer {
  position: relative;

  .profile-image-board-animation {
    animation: image-board-grow 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  }

  .profile-image-board {
    width: 100%;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
    transform: translate(0, -340px);
    height: 0;
    overflow: hidden;

    .triangle-down {
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 50px solid white;
      position: absolute;
      left: 2%;
      top: 320px;
      z-index: 2;
    }

    .profile-image-board-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .profile-image-div {
        flex-basis: 12%;

        img {
          border-radius: 50%;
          margin: 5px;
          border: 1px solid gray;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  @keyframes image-board-grow {
    0% {
      height: 0;
      padding: 0 0;
      overflow: hidden;
    }

    100% {
      height: 0;
      padding: 0 0;
    }
  }

  .contact-title {
    font-size: 4rem !important;
  }

  .contact-container {
    width: 100% !important;
    .contact-display {
      max-width: 100% !important;

      .comment {
        .comment-info {
          width: 15%;

          img {
            width: 100% !important;
            margin-top: 10px;
          }
        }
        .comment-content {
          .triangle-left {
            left: 11px !important;
          }
          .triangle-left-blue {
            left: 10px;
          }

          .comment-text {
            width: 90%;
            margin-left: 10px;
          }
        }
      }
    }

    .contact-form {
      img {
        width: 40% !important;
      }

      .form-username {
        margin-top: 20px;
      }

      small {
        top: 30% !important;
      }
    }
  }
}
