.projects {
  min-height: 100vh;
  padding: 15vh 0;
  background: url("../../src/images/bg-4.jpg") whitesmoke;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100vh;

  .project-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    font-weight: bold;
  }

  @keyframes show-project {
    0% {
      opacity: 0;
      transform: translate(-20px, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .project-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .project {
      background-color: whitesmoke;
      width: 30%;
      margin: 1%;
      height: 300px;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
        0 5px 15px 0 rgba(0, 0, 0, 0.08);
      transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      padding: 0 15px;
      cursor: pointer;
      opacity: 0;

      .project-icon {
        font-size: 4vw;
      }

      .project-name {
        font-family: "Dancing Script", cursive !important;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .project-content {
        font-family: "Rajdhani", sans-serif;
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    .project-animation {
      animation: show-project 0.7s ease-in-out forwards;
    }
  }
}

@media (max-width: 768px) {
  .projects {
    .project-container {
      max-width: 100% !important;

      .project {
        height: 400px;
      }
    }
  }
}

@media (max-width: 420px) {
  .project-title {
    font-size: 4rem !important;
  }

  .projects {
    .project-container {
      flex-direction: column;

      .project {
        height: auto;
        width: 100% !important;
        font-size: 1.2rem;
        margin: 15px 0;
        padding-bottom: 20px;

        .project-icon {
          font-size: 3rem;
        }

        &:hover {
          transform: scale(1);
        }
      }
    }
  }
}
