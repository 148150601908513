.profile {
  background: url("../../src/images/bg-7.png"), white;
  background-size: 100% 100%;
  padding-top: 15vh;
  min-height: 100vh;

  .profile-content {
    .profile-text {
      width: 60%;
      box-sizing: border-box;
      padding: 0 7%;
      float: left;
      font-family: "Rajdhani", sans-serif;
      line-height: 30px;
    }

    .profile-img {
      width: 40%;
      box-sizing: border-box;
      float: left;

      img {
        width: 75%;
      }
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

.line-through {
  text-decoration: line-through;
}

@media (max-width: 1024px) {
  .profile {
    background: white;
    .profile-content {
      .profile-text {
        font-size: 1.5rem;
        width: 100%;
      }
    }
  }
}

@media (max-width: 420px) {
  .sidebar-appear {
    width: 60%;
    transform: translate(0, 0);
  }

  .profile {
    background: white;
    padding-bottom: 40px;

    .profile-content {
      .profile-text {
        font-size: 1rem;
        width: 100%;
      }

      .profile-img {
        width: 150%;
        margin-bottom: 20px;
      }
    }
  }
}
