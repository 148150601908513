@import url(https://fonts.googleapis.com/css?family=Rajdhani);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
@import url(https://fonts.googleapis.com/css?family=ZCOOL+XiaoWei);
/* Google Fonts */
/* Custom partials */
/* colors */
/* overwrite headroom */
.headroom--unpinned {
  -webkit-transition: none !important;
  transition: none !important; }

/*
 * Animation Style
 */
/* overwrite Bootstrap */
.navbar.transparent.navbar-inverse .navbar-inner {
  border-width: 0px;
  box-shadow: 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%); }

.navbar-container {
  height: 0;
  background: transparent;
  overflow: visible; }

.navbar i {
  cursor: pointer; }

.navbar .logo {
  cursor: pointer; }

.navbar .dropdown {
  position: relative;
  display: inline-block; }
  .navbar .dropdown .dropbtn {
    background: transparent;
    color: black;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 100px; }
  .navbar .dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: center;
    padding: 5px; }
    .navbar .dropdown .dropdown-content p {
      color: black;
      text-decoration: none;
      display: block;
      padding: 15px 0;
      margin: 0;
      cursor: pointer; }
      .navbar .dropdown .dropdown-content p:hover {
        background-color: skyblue; }

.navbar .dropdown:hover .dropdown-content {
  display: block; }

.navbar .dropdown:hover .dropbtn {
  background-color: palevioletred; }

@media (max-width: 420px) {
  .navbar-container {
    height: 70px;
    position: fixed;
    background: white;
    width: 100%;
    z-index: 2000; }
  nav {
    padding: 0;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .navbar .logo {
    width: 30%;
    margin: 0; }
    .navbar .logo .nav-logo {
      margin-left: 0 !important; }
  .navbar .nav-right i {
    margin: 0 !important; } }

.sidebar {
  background-color: rgba(27, 25, 25, 0.9);
  height: 100vh;
  padding-top: 5%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  width: 20%;
  -webkit-transform: translate(120%, 0);
          transform: translate(120%, 0);
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  overflow: hidden; }
  .sidebar .sidebar-container {
    height: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding-left: 15%;
    position: relative; }
    .sidebar .sidebar-container .link-container {
      height: 10%; }
      .sidebar .sidebar-container .link-container a {
        cursor: pointer;
        position: relative;
        text-decoration: none;
        color: rgba(231, 216, 216, 0.7); }
        .sidebar .sidebar-container .link-container a:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          top: 22px;
          background-color: whitesmoke;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s; }
        .sidebar .sidebar-container .link-container a:hover {
          color: whitesmoke; }
          .sidebar .sidebar-container .link-container a:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1); }
    .sidebar .sidebar-container .social-container i {
      cursor: pointer;
      color: whitesmoke;
      font-size: 2rem;
      margin: 0 10px; }

.sidebar-appear {
  width: 20%;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

@media (max-width: 768px) {
  .sidebar {
    width: 40%; }
  .sidebar-appear {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@media (max-width: 420px) {
  .sidebar {
    z-index: 3000;
    width: 60%; }
    .sidebar .sidebar-container .link-container {
      padding: 20px 0;
      font-size: 1.2rem; }
  .sidebar-appear {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@-webkit-keyframes showText {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, -20px);
            transform: translate(0, -20px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes showText {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, -20px);
            transform: translate(0, -20px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@-webkit-keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-20px, -20px);
            transform: rotate(45deg) translate(-20px, -20px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(20px, 20px);
            transform: rotate(45deg) translate(20px, 20px); } }

@keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-20px, -20px);
            transform: rotate(45deg) translate(-20px, -20px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(20px, 20px);
            transform: rotate(45deg) translate(20px, 20px); } }

.index {
  height: 100vh;
  background: url(/static/media/bg-7.b54aefb1.png) whitesmoke;
  background-size: 100% 100%;
  background-attachment: fixed;
  position: relative; }
  .index .index-box {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
    .index .index-box .index-name {
      opacity: 0;
      -webkit-transform: translate(0, -20px);
              transform: translate(0, -20px);
      font-family: "Dancing Script", cursive;
      font-size: 8rem;
      -webkit-animation: showText 0.5s 0.5s ease-in-out forwards;
      animation: showText 0.5s 0.5s ease-in-out forwards; }
    .index .index-box .index-intro, .index .index-box .index-intro-2 {
      opacity: 0;
      -webkit-transform: translate(0, -20px);
              transform: translate(0, -20px);
      font-size: 2rem;
      margin: 10px 0;
      font-family: "Rajdhani", sans-serif;
      font-weight: bold;
      -webkit-animation: showText 0.5s 1s ease-in-out forwards;
      animation: showText 0.5s 1s ease-in-out forwards; }
    .index .index-box .index-intro-2 {
      -webkit-animation: showText 0.5s 1.5s ease-in-out forwards;
      animation: showText 0.5s 1.5s ease-in-out forwards; }
    .index .index-box .arrow {
      margin-top: 200px;
      cursor: pointer;
      padding: 0 auto;
      margin-left: 50%; }
      .index .index-box .arrow span {
        display: block;
        width: 30px;
        height: 30px;
        border-bottom: 5px solid violet;
        border-right: 5px solid violet;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        margin: -10px;
        -webkit-animation: animate 2s infinite;
                animation: animate 2s infinite; }
        .index .index-box .arrow span:nth-child(2) {
          -webkit-animation-delay: -0.2s;
                  animation-delay: -0.2s; }
        .index .index-box .arrow span:nth-child(3) {
          -webkit-animation-delay: -0.4s;
                  animation-delay: -0.4s; }

.intro {
  height: 200px; }
  .intro .change-intro {
    border-bottom: 1px solid black;
    font-size: 5vw;
    font-family: "Raleway", sans-serif; }
    .intro .change-intro .cursor {
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: 100; }
  .intro .sub-intro {
    font-size: 1rem;
    font-family: "Raleway", sans-serif; }

@media (max-width: 1024px) {
  .index {
    background: url(/static/media/bg-row-2.00311141.jpg) whitesmoke;
    background-size: cover;
    background-attachment: fixed; }
  .intro .change-intro {
    font-size: 4rem; }
  .intro .sub-intro {
    font-size: 2rem; } }

@media (max-width: 420px) {
  .index .index-box .index-name {
    font-size: 5rem; } }

.profile {
  background: url(/static/media/bg-7.b54aefb1.png), white;
  background-size: 100% 100%;
  padding-top: 15vh;
  min-height: 100vh; }
  .profile .profile-content .profile-text {
    width: 60%;
    box-sizing: border-box;
    padding: 0 7%;
    float: left;
    font-family: "Rajdhani", sans-serif;
    line-height: 30px; }
  .profile .profile-content .profile-img {
    width: 40%;
    box-sizing: border-box;
    float: left; }
    .profile .profile-content .profile-img img {
      width: 75%; }
  .profile .profile-content:after {
    content: "";
    display: table;
    clear: both; }

.line-through {
  text-decoration: line-through; }

@media (max-width: 1024px) {
  .profile {
    background: white; }
    .profile .profile-content .profile-text {
      font-size: 1.5rem;
      width: 100%; } }

@media (max-width: 420px) {
  .sidebar-appear {
    width: 60%;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .profile {
    background: white;
    padding-bottom: 40px; }
    .profile .profile-content .profile-text {
      font-size: 1rem;
      width: 100%; }
    .profile .profile-content .profile-img {
      width: 150%;
      margin-bottom: 20px; } }

.experience {
  min-height: 100vh;
  padding-top: 10vh;
  background: url(/static/media/bg-7.44f443d9.jpg) whitesmoke;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100vh; }
  .experience .experience-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    cursor: auto !important;
    color: black !important; }
  .experience h4 {
    font-family: "Rajdhani", "ZCOOL XiaoWei", serif;
    font-weight: bold; }
    .experience h4:hover {
      color: palevioletred;
      cursor: pointer; }
  .experience .subtitle {
    font-family: "Dancing Script", cursive !important;
    font-size: 1.2vw; }
  .experience .exp-image {
    width: 90%; }

/* overwrite react-vertical-timeline-component */
.vertical-timeline-element:nth-child(even) .vertical-timeline-element-content {
  -webkit-transform: translate(-50px, 0);
          transform: translate(-50px, 0); }

.vertical-timeline-element:nth-child(odd) .vertical-timeline-element-content {
  -webkit-transform: translate(50px, 0);
          transform: translate(50px, 0); }

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px); } }

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px); } }

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(50px);
    transform: translateX(50px); } }

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(50px);
    transform: translateX(50px); } }

@media (max-width: 1169px) {
  /* overwrite react-vertical-timeline-component */
  .vertical-timeline-element:nth-child(even) .vertical-timeline-element-content {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .vertical-timeline-element:nth-child(odd) .vertical-timeline-element-content {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  @-webkit-keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      transform: translateX(100px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px); } }
  @keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      transform: translateX(100px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px); } }
  @-webkit-keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px); } }
  @keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px); } } }

@media (max-width: 1024px) {
  .experience {
    background: url(/static/media/bg-row-4.68f1f48e.jpg) whitesmoke;
    background-size: 100% 100%;
    background-attachment: fixed; }
  .subtitle {
    font-size: 1rem !important; } }

@media (max-width: 420px) {
  .experience {
    background: url(/static/media/bg-row-3.3381d890.jpg) whitesmoke;
    background-size: cover;
    background-attachment: fixed; }
  .experience-title {
    font-size: 4rem !important; }
  .subtitle {
    font-size: 1rem !important; } }

@-webkit-keyframes showInfoBoard {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    display: none; }
  95% {
    opacity: 1;
    width: 450px;
    height: 150px; }
  100% {
    opacity: 1;
    width: 450px;
    height: 150px; } }

@keyframes showInfoBoard {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    display: none; }
  95% {
    opacity: 1;
    width: 450px;
    height: 150px; }
  100% {
    opacity: 1;
    width: 450px;
    height: 150px; } }

@-webkit-keyframes showTriangle {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes showTriangle {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.skills {
  min-height: 100vh;
  padding: 15vh 0;
  background: whitesmoke;
  position: relative; }
  .skills .skill-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    font-weight: bold; }
  .skills .skill-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
    .skills .skill-container .skill {
      padding: 3%;
      width: 20%;
      box-sizing: border-box;
      position: relative;
      white-space: nowrap; }
      .skills .skill-container .skill .triangle-down {
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 50px solid white;
        position: absolute;
        left: 80px;
        top: 95px;
        z-index: 110;
        display: block;
        opacity: 0; }
      .skills .skill-container .skill .triangle-animation {
        -webkit-animation: showTriangle 0.5s 0.5s ease-in-out forwards;
                animation: showTriangle 0.5s 0.5s ease-in-out forwards; }
      .skills .skill-container .skill .info-board-animation {
        -webkit-animation: showInfoBoard 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
                animation: showInfoBoard 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards; }
      .skills .skill-container .skill .info-board-left, .skills .skill-container .skill .info-board-right {
        top: 0;
        left: 0;
        -webkit-transform: translate(0, -50px);
                transform: translate(0, -50px);
        height: 0;
        width: 0;
        opacity: 0;
        display: none;
        background: white;
        position: absolute;
        text-align: left;
        z-index: 100;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
        cursor: default;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-grow: 1;
                flex-grow: 1; }
      .skills .skill-container .skill .info-board-right {
        -webkit-transform: translate(-200px, -50px);
                transform: translate(-200px, -50px); }
  .skills .process-bar {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: help; }
  .skills .process-bar-scale {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

@media (max-width: 768px) {
  .triangle-down {
    left: 40px !important;
    top: 80px !important; } }

@media (max-width: 420px) {
  @-webkit-keyframes showInfoBoard {
    0% {
      opacity: 0;
      width: 0;
      height: 0;
      display: none; }
    95% {
      opacity: 1;
      width: 100%;
      height: 300px; }
    100% {
      opacity: 1;
      width: 100%;
      height: 300px; } }
  @keyframes showInfoBoard {
    0% {
      opacity: 0;
      width: 0;
      height: 0;
      display: none; }
    95% {
      opacity: 1;
      width: 100%;
      height: 300px; }
    100% {
      opacity: 1;
      width: 100%;
      height: 300px; } }
  @-webkit-keyframes showTriangle {
    0% {
      opacity: 0; }
    100% {
      opacity: 0; } }
  @keyframes showTriangle {
    0% {
      opacity: 0; }
    100% {
      opacity: 0; } }
  .skill-title {
    font-size: 4rem !important; }
  .skill-container {
    -webkit-flex-direction: column;
            flex-direction: column; }
    .skill-container .skill {
      width: 70% !important;
      margin: 50px 15%; }
      .skill-container .skill p {
        font-size: 1.5rem; }
      .skill-container .skill .info-board-left, .skills .skill-container .skill .info-board-right {
        width: 80%;
        white-space: normal;
        font-size: 1.5rem;
        -webkit-transform: translate(0, -50px);
                transform: translate(0, -50px); }
      .skill-container .skill .info-board-right {
        white-space: normal;
        -webkit-transform: translate(0, -50px);
                transform: translate(0, -50px);
        font-size: 1.5rem; } }

.projects {
  min-height: 100vh;
  padding: 15vh 0;
  background: url(/static/media/bg-4.2766d252.jpg) whitesmoke;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100vh; }
  .projects .project-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    font-weight: bold; }

@-webkit-keyframes show-project {
  0% {
    opacity: 0;
    -webkit-transform: translate(-20px, 0);
            transform: translate(-20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes show-project {
  0% {
    opacity: 0;
    -webkit-transform: translate(-20px, 0);
            transform: translate(-20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }
  .projects .project-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
    .projects .project-container .project {
      background-color: whitesmoke;
      width: 30%;
      margin: 1%;
      height: 300px;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
      -webkit-transition: -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      padding: 0 15px;
      cursor: pointer;
      opacity: 0; }
      .projects .project-container .project .project-icon {
        font-size: 4vw; }
      .projects .project-container .project .project-name {
        font-family: "Dancing Script", cursive !important;
        font-size: 1.5rem;
        font-weight: bold; }
      .projects .project-container .project .project-content {
        font-family: "Rajdhani", sans-serif; }
      .projects .project-container .project:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05); }
    .projects .project-container .project-animation {
      -webkit-animation: show-project 0.7s ease-in-out forwards;
              animation: show-project 0.7s ease-in-out forwards; }

@media (max-width: 768px) {
  .projects .project-container {
    max-width: 100% !important; }
    .projects .project-container .project {
      height: 400px; } }

@media (max-width: 420px) {
  .project-title {
    font-size: 4rem !important; }
  .projects .project-container {
    -webkit-flex-direction: column;
            flex-direction: column; }
    .projects .project-container .project {
      height: auto;
      width: 100% !important;
      font-size: 1.2rem;
      margin: 15px 0;
      padding-bottom: 20px; }
      .projects .project-container .project .project-icon {
        font-size: 3rem; }
      .projects .project-container .project:hover {
        -webkit-transform: scale(1);
                transform: scale(1); } }

.contact {
  min-height: 100vh;
  padding: 15vh 0;
  background-color: whitesmoke; }
  .contact .contact-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    font-weight: bold; }
  .contact .contact-container .contact-display {
    padding: 20px 10px;
    height: 85vh;
    overflow-y: scroll;
    background: white;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
    .contact .contact-container .contact-display .comment {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      margin: 10px 0; }
      .contact .contact-container .contact-display .comment .comment-info {
        width: 15%; }
        .contact .contact-container .contact-display .comment .comment-info img {
          width: 60%;
          border-radius: 50%;
          border: 1px solid gray; }
      .contact .contact-container .contact-display .comment .comment-content {
        width: 100%;
        position: relative; }
        .contact .contact-container .contact-display .comment .comment-content .triangle-left, .contact .contact-container .contact-display .comment .comment-content .triangle-left-blue {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 15px solid rgba(216, 112, 147, 0.6);
          border-bottom: 10px solid transparent;
          position: absolute;
          top: 22px;
          left: 0;
          -webkit-transform: translate(-15px, 0);
                  transform: translate(-15px, 0); }
        .contact .contact-container .contact-display .comment .comment-content .triangle-left-blue {
          border-right: 15px solid paleturquoise; }
        .contact .contact-container .contact-display .comment .comment-content .comment-dateTime {
          font-family: "Dancing Script", cursive;
          font-size: 0.9rem;
          display: block;
          -webkit-transform: translate(0, -10px);
                  transform: translate(0, -10px); }
        .contact .contact-container .contact-display .comment .comment-content .comment-text, .contact .contact-container .contact-display .comment .comment-content .comment-text-blue {
          background-color: rgba(216, 112, 147, 0.6);
          min-height: 50px;
          width: 60%;
          -webkit-transform: translate(0, -8px);
                  transform: translate(0, -8px);
          padding: 10px 15px; }
        .contact .contact-container .contact-display .comment .comment-content .comment-text-blue {
          background-color: paleturquoise; }
  .contact .contact-container .contact-form img {
    width: 10%;
    border-radius: 50%;
    border: 1px solid gray;
    z-index: 2; }
  .contact .contact-container .contact-form .form-username {
    line-height: 30px;
    width: 200px;
    padding: 5px 10px;
    border: none;
    border-bottom: 1px solid black;
    background: none;
    -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px); }
    .contact .contact-container .contact-form .form-username:focus {
      outline: none; }
  .contact .contact-container .contact-form .form-button {
    background: palevioletred; }
    .contact .contact-container .contact-form .form-button:hover {
      background: #cc2448; }

@-webkit-keyframes image-board-grow {
  0% {
    height: 0;
    padding: 0 0;
    overflow: hidden; }
  95% {
    height: 330px;
    padding: 40px 0;
    overflow: hidden; }
  100% {
    height: 330px;
    padding: 40px 0;
    overflow: visible; } }

@keyframes image-board-grow {
  0% {
    height: 0;
    padding: 0 0;
    overflow: hidden; }
  95% {
    height: 330px;
    padding: 40px 0;
    overflow: hidden; }
  100% {
    height: 330px;
    padding: 40px 0;
    overflow: visible; } }

.profile-image-outercontainer {
  position: relative; }
  .profile-image-outercontainer .profile-image-board-animation {
    -webkit-animation: image-board-grow 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
            animation: image-board-grow 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards; }
  .profile-image-outercontainer .profile-image-board {
    width: 100%;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    -webkit-transform: translate(0, -340px);
            transform: translate(0, -340px);
    height: 0;
    overflow: hidden; }
    .profile-image-outercontainer .profile-image-board .triangle-down {
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 50px solid white;
      position: absolute;
      left: 2%;
      top: 320px;
      z-index: 2; }
    .profile-image-outercontainer .profile-image-board .profile-image-board-row {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly; }
      .profile-image-outercontainer .profile-image-board .profile-image-board-row .profile-image-div {
        -webkit-flex-basis: 12%;
                flex-basis: 12%; }
        .profile-image-outercontainer .profile-image-board .profile-image-board-row .profile-image-div img {
          border-radius: 50%;
          margin: 5px;
          border: 1px solid gray;
          width: 100%;
          cursor: pointer; }

@media (max-width: 420px) {
  @-webkit-keyframes image-board-grow {
    0% {
      height: 0;
      padding: 0 0;
      overflow: hidden; }
    100% {
      height: 0;
      padding: 0 0; } }
  @keyframes image-board-grow {
    0% {
      height: 0;
      padding: 0 0;
      overflow: hidden; }
    100% {
      height: 0;
      padding: 0 0; } }
  .contact-title {
    font-size: 4rem !important; }
  .contact-container {
    width: 100% !important; }
    .contact-container .contact-display {
      max-width: 100% !important; }
      .contact-container .contact-display .comment .comment-info {
        width: 15%; }
        .contact-container .contact-display .comment .comment-info img {
          width: 100% !important;
          margin-top: 10px; }
      .contact-container .contact-display .comment .comment-content .triangle-left, .contact .contact-container .contact-display .comment .comment-content .triangle-left-blue {
        left: 11px !important; }
      .contact-container .contact-display .comment .comment-content .triangle-left-blue {
        left: 10px; }
      .contact-container .contact-display .comment .comment-content .comment-text, .contact .contact-container .contact-display .comment .comment-content .comment-text-blue {
        width: 90%;
        margin-left: 10px; }
    .contact-container .contact-form img {
      width: 40% !important; }
    .contact-container .contact-form .form-username {
      margin-top: 20px; }
    .contact-container .contact-form small {
      top: 30% !important; } }

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

footer {
  font-family: "Rajdhani", sans-serif;
  padding-top: 10vh;
  background: rgba(27, 25, 25, 0.9);
  padding-bottom: 40px;
  color: whitesmoke; }

.loader-container h2 {
  font-family: "Dancing Script", cursive !important; }

