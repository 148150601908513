/* overwrite Bootstrap */
.navbar.transparent.navbar-inverse .navbar-inner {
  border-width: 0px;
  -webkit-box-shadow: 0px 0px;
  box-shadow: 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.navbar-container {
  height: 0;
  background: transparent;
  overflow: visible;
}

.navbar {
  i {
    cursor: pointer;
  }

  .logo {
    cursor: pointer;
  }

  .dropdown {
    position: relative;
    display: inline-block;

    .dropbtn {
      background: transparent;
      color: black;
      padding: 16px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      width: 100px;
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 100px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      text-align: center;
      padding: 5px;

      p {
        color: black;
        text-decoration: none;
        display: block;
        padding: 15px 0;
        margin: 0;
        cursor: pointer;

        &:hover {
          background-color: skyblue;
        }
      }
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: palevioletred;
  }
}

@media (max-width: 420px) {
  .navbar-container {
    height: 70px;
    position: fixed;
    background: white;
    width: 100%;
    z-index: 2000;
  }
  nav {
    padding: 0;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }
  .navbar {
    .logo {
      width: 30%;
      margin: 0;
      .nav-logo {
        margin-left: 0 !important;
      }
    }

    .nav-right {
      i {
        margin: 0 !important;
      }
    }
  }
}
