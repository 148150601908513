@keyframes showInfoBoard {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
  }
  95% {
    opacity: 1;
    width: 450px;
    height: 150px;
  }
  100% {
    opacity: 1;
    width: 450px;
    height: 150px;
  }
}

@keyframes showTriangle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.skills {
  min-height: 100vh;
  padding: 15vh 0;
  background: whitesmoke;
  position: relative;

  .skill-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    font-weight: bold;
  }

  .skill-container {
    display: flex;
    justify-content: space-evenly;

    .skill {
      padding: 3%;
      width: 20%;
      box-sizing: border-box;
      position: relative;
      white-space: nowrap;

      .triangle-down {
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 50px solid white;
        position: absolute;
        left: 80px;
        top: 95px;
        z-index: 110;
        display: block;
        opacity: 0;
      }

      .triangle-animation {
        animation: showTriangle 0.5s 0.5s ease-in-out forwards;
      }

      .info-board-animation {
        animation: showInfoBoard 1.5s cubic-bezier(0.075, 0.82, 0.165, 1)
          forwards;
      }

      .info-board-left {
        top: 0;
        left: 0;
        transform: translate(0, -50px);
        height: 0;
        width: 0;
        opacity: 0;
        display: none;
        background: white;
        position: absolute;
        text-align: left;
        z-index: 100;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
          0 5px 15px 0 rgba(0, 0, 0, 0.08);
        cursor: default;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
      }

      .info-board-right {
        @extend .info-board-left;
        transform: translate(-200px, -50px);
      }
    }
  }

  .process-bar {
    transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: help;
  }
  .process-bar-scale {
    transform: scale(1.2);
  }
}

@media (max-width: 768px) {
  .triangle-down {
    left: 40px !important;
    top: 80px !important;
  }
}

@media (max-width: 420px) {
  @keyframes showInfoBoard {
    0% {
      opacity: 0;
      width: 0;
      height: 0;
      display: none;
    }
    95% {
      opacity: 1;
      width: 100%;
      height: 300px;
    }
    100% {
      opacity: 1;
      width: 100%;
      height: 300px;
    }
  }

  @keyframes showTriangle {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .skill-title {
    font-size: 4rem !important;
  }

  .skill-container {
    flex-direction: column;
    .skill {
      width: 70% !important;
      margin: 50px 15%;

      p {
        font-size: 1.5rem;
      }

      .info-board-left {
        width: 80%;
        white-space: normal;
        font-size: 1.5rem;
        transform: translate(0, -50px);
      }

      .info-board-right {
        white-space: normal;
        transform: translate(0, -50px);
        font-size: 1.5rem;
      }
    }
  }
}
