.experience {
  min-height: 100vh;
  padding-top: 10vh;
  background: url("../../src/images/bg-7.jpg") whitesmoke;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100vh;

  .experience-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 4vw;
    cursor: auto !important;
    color: black !important;
  }

  h4 {
    font-family: "Rajdhani", "ZCOOL XiaoWei", serif;
    font-weight: bold;

    &:hover {
      color: palevioletred;
      cursor: pointer;
    }
  }

  .subtitle {
    font-family: "Dancing Script", cursive !important;
    font-size: 1.2vw;
  }

  .exp-image {
    width: 90%;
  }
}

/* overwrite react-vertical-timeline-component */
.vertical-timeline-element:nth-child(even) .vertical-timeline-element-content {
  transform: translate(-50px, 0);
}

.vertical-timeline-element:nth-child(odd) .vertical-timeline-element-content {
  transform: translate(50px, 0);
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@media (max-width: 1169px) {
  /* overwrite react-vertical-timeline-component */
  .vertical-timeline-element:nth-child(even)
    .vertical-timeline-element-content {
    transform: translate(0, 0);
  }

  .vertical-timeline-element:nth-child(odd) .vertical-timeline-element-content {
    transform: translate(0, 0);
  }

  @keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      -moz-transform: translateX(100px);
      -ms-transform: translateX(100px);
      -o-transform: translateX(100px);
      transform: translateX(100px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(-0px);
      -moz-transform: translateX(-0px);
      -ms-transform: translateX(-0px);
      -o-transform: translateX(-0px);
      transform: translateX(-0px);
    }
  }

  @keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
      -moz-transform: translateX(-100px);
      -ms-transform: translateX(-100px);
      -o-transform: translateX(-100px);
      transform: translateX(-100px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
}

@media (max-width: 1024px) {
  .experience {
    background: url("../../src/images/bg-row-4.jpg") whitesmoke;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  .subtitle {
    font-size: 1rem !important;
  }
}

@media (max-width: 420px) {
  .experience {
    background: url("../../src/images/bg-row-3.jpg") whitesmoke;
    background-size: cover;
    background-attachment: fixed;
  }
  .experience-title {
    font-size: 4rem !important;
  }

  .subtitle {
    font-size: 1rem !important;
  }
}
