/* Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Rajdhani");
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300");
@import url("https://fonts.googleapis.com/css?family=Dancing+Script");
@import url("https://fonts.googleapis.com/css?family=ZCOOL+XiaoWei");

/* Custom partials */
@import "./partials/mixin";
@import "./partials/navbar";
@import "./partials/sidebar";
@import "./partials/index";
@import "./partials/profile";
@import "./partials/experience";
@import "./partials/skills";
@import "./partials/projects";
@import "./partials/contact";

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

footer {
  font-family: "Rajdhani", sans-serif;
  padding-top: 10vh;
  background: $transparent-black;
  padding-bottom: 40px;
  color: whitesmoke;
}

.loader-container {
  h2 {
    font-family: "Dancing Script", cursive !important;
  }
}
